import checkMetaMaskInstallation from "./checkMetaMaskInstallation";
const isMetaMaskInstalled = checkMetaMaskInstallation();

const connectMetaMask = async () => {
    if (isMetaMaskInstalled) {
        try {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const account = accounts[0];
            //console.log(await window.ethereum.request({ method: 'eth_requestAccounts' }));
            localStorage.setItem('isMetaMaskConnected', 'true');
            //console.log("Account:" + account)
            return account;
        } catch (error) {
            //console.error('Error connecting to MetaMask:', error);
            localStorage.setItem('isMetaMaskConnected', 'false');
            return null;
        }
    } else {
        localStorage.setItem('isMetaMaskConnected', 'false');
        return null;
    }
};

export default connectMetaMask;