

const TokenCard = ({tokenName, tokenBalance, tokenSymbol}) => {


    if(tokenName){
        return (
            <div className={"col-span-6 sm:col-span-6 md:col-span-3 lg:col-span-3 xl:col-span-3"}>
                <div className="card h-[150px]">
                    <h3 className="h1">{tokenName} ({tokenSymbol})</h3>
                    <span className={"card-content"}>{tokenBalance}</span>
                </div>
            </div>
        );
    }


}
export default TokenCard;