
import React, {useState} from "react";
import axios from "axios";

const Referral = ({ onData }) => {

    const [inputTGValue, setInputTGValue] = useState('');
    const [inputXValue, setInputXValue] = useState('');
    const [socialErrorMessage, setSocialErrorMessage] = useState(null);

    const handleInputTGChange = (event) => {
        setInputTGValue(event.target.value);
        onData(event.target.value); // Callback-Funktion aufrufen und Daten übergeben
    };
    const handleInputXChange = (event) => {
        setInputXValue(event.target.value);
        onData(event.target.value); // Callback-Funktion aufrufen und Daten übergeben
    };

    const saveSocialData = async () => {
        //console.log("Telegram Name: " + inputTGValue);
        //console.log("X-Name: " + inputXValue);

        if( !inputTGValue || !inputXValue ){
            setSocialErrorMessage("Please fill in all fields");
            return;
        }else{
            setSocialErrorMessage(null);
        }

        const token = localStorage.getItem('token');
        try {
            const auth = await axios.get('https://api.thejefftoken.com/protected.php', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if( auth ){

                // Validate User Input
                // Entferne führendes @
                inputTGValue.startsWith('@') ? inputTGValue.substring(1) : inputTGValue;
                inputXValue.startsWith('@') ? inputXValue.substring(1) : inputXValue;

                // Regex für URL-Erkennung
                const urlPattern = /(https?:\/\/[^\s]+)/g;
                // Entferne URLs
                inputTGValue.replace(urlPattern, '').trim();
                inputXValue.replace(urlPattern, '').trim();

                const escapeSpecialChars = (string) => {
                    // Escape for RegExp
                    const escapedString = string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                    // Escape for HTML
                    return escapedString.replace(/&/g, '&amp;')
                        .replace(/</g, '&lt;')
                        .replace(/>/g, '&gt;')
                        .replace(/"/g, '&quot;')
                        .replace(/'/g, '&#039;');
                };

                const telegram = escapeSpecialChars(inputTGValue);
                const xcom = escapeSpecialChars(inputXValue);

                const data = {
                    type: 'social',
                    telegram: telegram,
                    x: xcom
                };
                await axios.post('https://api.thejefftoken.com/protected.php', data, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }).then(response => {
                    // Erfolgreiche Antwort vom Server
                    //console.log(response.data.record.referral_id);
                    setSocialErrorMessage(response.data.message);
                    window.location.reload();
                })
                    .catch(error => {
                        // Fehler beim Request
                        console.error('Error:', error);
                        //setSocialErrorMessage(error.response.message);
                    });
            }

        } catch (error) {
            setSocialErrorMessage('Failed to fetch data: ' + error);
        }

    }

    return (
        <div className="card">
            <h3 className="h1">Unlock Your Referral-Code:</h3>
            <p>Follow us on <a href={"https://t.co/SZtLNo0Ze3"} className="text-blue-500" target={"_blank"}
            rel="noreferrer">Telegram</a> and <a href={"https://x.com/thejefftoken"} className="text-blue-500" target={"_blank"}
            rel="noreferrer">x.com</a>, invite your friends to boost you earnings</p>
            <input
                name="tgname"
                className={"w-[200px]"}
                value={inputTGValue}
                placeholder="Telegram Name"
                type="text"
                onChange={handleInputTGChange}
            />
            <input
                name="xname"
                className={"w-[200px]"}
                value={inputXValue}
                placeholder={"X-Name"}
                type="text"
                onChange={handleInputXChange}
            />
            <button onClick={saveSocialData} className={"btn-primary"}>Save</button>
            <div>
                {socialErrorMessage && <p className="error-message">{socialErrorMessage}</p>}
            </div>
            <small>I am aware that any misuse of this service will result in exclusion and loss of access and loss of gains</small>
        </div>
    );


}
export default Referral;