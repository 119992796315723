
// Tutorial: https://www.youtube.com/watch?v=ipKCKB3PCpk
// Bei 32:55Min weiter machen.

import React, {useEffect, useState } from "react";

import {ethers} from "ethers";
import TokenCard from "./Components/stakingTokenCard";
import Referral from "./Components/Referral";

import logo from './logo.svg';
import axios from "axios";

import {stakingTokenAddress} from './Contracts/abiStakingToken';
import {stakingTokenABI} from "./Contracts/abiStakingToken";

import {rewardTokenAddress} from "./Contracts/abiRewardToken";
import {rewardTokenABI} from "./Contracts/abiRewardToken";

import{stakingContractAddress} from "./Contracts/abiStakingContract";
import{stakingContractABI} from "./Contracts/abiStakingContract";

import checkMetaMaskInstallation from "./Utils/checkMetaMaskInstallation";
import connectMetaMask from "./Utils/connectMetaMask";

import IchangeNetwork from './images/networkChange.jpg';
import burgerflip from './images/burgerflip.gif';

const md5 = require('md5');
import CountUp from 'react-countup';
import TopStakingBoard from "./Components/TopStakingBoard";


const Wallet = () => {

    const REACT_APP_PROD = true;

    const [hashMessage, setHashMessage] = useState(null);
    const [isMetaMaskInstalled, setIsMetaMaskInstalled] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    // Staking Token
    const [stakingTokenName, setStakingTokenName] = useState("");
    const [stakingTokenBalance, setStakingTokenBalance] = useState(null);
    const [stakingTokenSymbol, setStakingTokenSymbol] = useState("");

    // Reward Token
    const [rewardTokenName, setRewardTokenName ] = useState("");
    const [rewardTokenBalance, setRewardTokenBalance ] = useState(null );
    const [rewardTokenSymbol, setRewardTokenSymbol ] = useState("");

    // Wallet and Chain
    const [connected, setConnected] = useState(false);
    const [walletAddress, setWalletAddress] = useState("");
    let chainId = 0;
    REACT_APP_PROD ? chainId = "8453" : chainId = "84532"; // 84532 base-sepolia / 8453 base-mainnet

    const [isRightNetwork, setIsRightNetwork] = useState(false);
    const [staking, setStaking] = useState(false);
    const [claiming, setClaiming] = useState(false);
    //const [awaitNetworkSwitch, setAwaitNetworkSwitch ] = useState( false );
    const [claimingRate, setClaimingRate ] = useState( 0 );

    //const [signer, setSigner] = useState(null);
    const [stakeAmount, setStakeAmount] = useState('1');
    const [isLoadingStakingButton, setIsLoadingStakingButton] = useState(false);

    const [inStakeAmount, setInStakeAmount] = useState(0);
    const [claimableReward, setClaimableReward] = useState(0);
    const [claimingErrorMessage, setClaimingErrorMessage] = useState(null);

    const [referralId, setReferralId] = useState('');
    const [userWasReferred, setUserWasReferred] = useState(false);
    const [userReferredBy, setUserReferredBy] = useState('');
    const handleReferralData = () => {

    }


    // Check Referral URL -> Set Cookie redirect
    const currentUrl = window.location.href;
    const pathParts = currentUrl.split('/');

    if (pathParts.length === 5 && pathParts[3] === 'ref') {
        const ref = pathParts[4];

        const name = 'jeff_ref';
        const value = ref;
        const path = '/';
        const days = 7;
        const expires = new Date(Date.now() + days * 864e5).toUTCString()
        document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path
        window.location.href = '/';
        return;
    }

    // Was the User Referred? -> Read Cookie für Referral
    useEffect(() => {
        if (document.cookie.length > 0) {
            const c_name = 'jeff_ref';
            let cookieParts = document.cookie.split('=');
            if(cookieParts[0] === c_name && cookieParts[1] !== undefined) {
                setUserWasReferred(true);
                setUserReferredBy(cookieParts[1]);
            }
        }
    })


    const [value, setValue] = useState(1);


    useEffect(() => {
        const isInstalled = checkMetaMaskInstallation();
        //console.log("Metamask installed: " + isInstalled);
        setIsMetaMaskInstalled(isInstalled);
    });



    useEffect(() => {
        const reconnectMetaMask = async () => {
            const isMetaMaskConnected = localStorage.getItem('isMetaMaskConnected');
            if (isMetaMaskConnected === 'true') {
                const account = await connectMetaMask();

                //console.log("Account Reconnect:" + account);
                setWalletAddress(account);
                setConnected(true);

                setReferralId( localStorage.getItem('referralID') );
                //connectWallet();
            }
        };

        reconnectMetaMask();
    }, [isMetaMaskInstalled]);



    // Check Network:
    const checkNetwork = async () => {

        const provider = new ethers.BrowserProvider(window.ethereum);
        const network = await provider.getNetwork();

        //console.log( "Network: " + network.chainId );
        //console.log(chainId);
        if(network.chainId === BigInt(chainId)){
            setIsRightNetwork(true);
            //setAwaitNetworkSwitch(false);
        }else{
            setErrorMessage("Connect to Base Network");
            setIsRightNetwork(false);
            //setAwaitNetworkSwitch(true);
        }
    }





    async function checkStaking(){
        if(connected){
            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            const stakingContract = new ethers.Contract(stakingContractAddress, stakingContractABI, signer);
            const checkStaking = await stakingContract.stakedBalance( walletAddress );

            //console.log(checkStaking)
            setInStakeAmount(Math.round(ethers.formatUnits(checkStaking, 18)));
            if(checkStaking >= 1){
                setStaking(true);
            }else{
                setStaking(false);
            }
        }
    }



    async function updateBalance(){
        const provider = new ethers.BrowserProvider( window.ethereum);
        const signer = await provider.getSigner();
        const stakingTokenContract = new ethers.Contract(stakingTokenAddress, stakingTokenABI, signer);
        const stakingTokenBalance = await stakingTokenContract.balanceOf( walletAddress );
        setStakingTokenBalance(ethers.formatUnits(stakingTokenBalance, 18));

        const rewardTokenContract = new ethers.Contract(rewardTokenAddress, rewardTokenABI, signer);
        const rewardTokenBalance = await rewardTokenContract.balanceOf( walletAddress );
        setRewardTokenBalance(ethers.formatUnits(rewardTokenBalance, 18));
    }



    async function connectWallet() {

        // Temporary Disable Wallet Connection. Do nothing
        //return;

        //console.log(isrightnetwork);
         if (!connected) {
             const provider = new ethers.BrowserProvider(window.ethereum);
             //const signer = await provider.getSigner();


             // Benutzer nach ihrer Zustimmung zur Verbindung mit ihrem Wallet fragen
             const accounts = await provider.send('eth_requestAccounts', []);

             // Aktuelle Adresse des Benutzers abrufen
             const _walletAddress = accounts[0];
             localStorage.setItem('isMetaMaskConnected', 'true');

             setConnected(true);
             setWalletAddress(_walletAddress);

             //console.log(_walletAddress)

             //getStakingContractDetails();
             //getRewardTokenContractDetails();

             // Update Database Values
              axios.post( 'https://api.thejefftoken.com/index.php', {
                wallet: _walletAddress,
                referral_id: md5(_walletAddress),
                referrer_id: userWasReferred ? userReferredBy : null
             }).then((response) => {

                 //console.log(response.data.token);
                 if(response.data.status === 1 && response.data.token ){
                     localStorage.setItem('token', response.data.token);
                     //console.log("Login was Successfull!");
                     localStorage.setItem('isMetaMaskConnected', 'true');
                 }

                 //console.log(response.data.record)
                 if(response.data.status === 1 && response.data.record ){

                    //console.log(response.data.record.x_id);
                    if(response.data.record.x_id && response.data.record.x_id){
                        setReferralId(response.data.record.referral_id);
                        localStorage.setItem('referralID', response.data.record.referral_id);
                        //console.log(response.data.record.referral_id);
                    }

                 }

                 const token = localStorage.getItem('token');
                  if(!token){
                      localStorage.setItem('token', response.data.token);
                  }

             });


         }else{
             setConnected(false);
             window.location.reload();
             localStorage.setItem('isMetaMaskConnected', 'false');
         }
     }


     async function getStakingContractDetails(){
         // Staking Token
         const provider = new ethers.BrowserProvider(window.ethereum);
         const stakingTokenContract = new ethers.Contract(stakingTokenAddress, stakingTokenABI, provider);
         const stakingTokenBalance = await stakingTokenContract.balanceOf( walletAddress );
         const stakingTokenName = await stakingTokenContract.name();
         const stakingTokenSymbol = await stakingTokenContract.symbol();

         setStakingTokenBalance(ethers.formatUnits(stakingTokenBalance, 18));
         setStakingTokenName(stakingTokenName);
         setStakingTokenSymbol(stakingTokenSymbol);

     }

     async function getRewardTokenContractDetails(){
         // Reward Token
         const provider = new ethers.BrowserProvider(window.ethereum);
         const rewardTokenContract = new ethers.Contract(rewardTokenAddress, rewardTokenABI, provider);
         const rewardTokenBalance = await rewardTokenContract.balanceOf(walletAddress);
         const rewardTokenName = await rewardTokenContract.name();
         const rewardTokenSymbol = await rewardTokenContract.symbol();

         setRewardTokenBalance(ethers.formatUnits(rewardTokenBalance, 18));
         setRewardTokenName(rewardTokenName);
         setRewardTokenSymbol(rewardTokenSymbol);

     }


     //Check claimable Reward
    async function checkClaimableReward(){

        //console.log("Check claimable Reward");
        try{
            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            const stakingContract =  await new ethers.Contract(stakingContractAddress, stakingContractABI, signer);
            const userStakedBalance = await stakingContract.stakedBalance(walletAddress);
            const rewardRate = await stakingContract.rewardRate();
            const lastClaimedTime = await stakingContract.lastClaimedTime(walletAddress);
            const currentTimeStamp = Math.floor(Date.now() / 1000);

            const totalTimePassed = Number(currentTimeStamp) - Number(lastClaimedTime);

            const claimableReward = Number(rewardRate) * totalTimePassed * Number(userStakedBalance) / 1e18;

            setClaimableReward( Number(rewardRate) * totalTimePassed * Number(userStakedBalance) / 1e18 / 10**18 );

            return  claimableReward / 10**18;

        }catch (error){
            //console.error(error);
        }

    }

    async function getClaimingRate( seconds  = 10 ){
        try{
            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            const stakingContract =  await new ethers.Contract(stakingContractAddress, stakingContractABI, signer);
            const rewardRate = await stakingContract.rewardRate();

            const userStakedBalance = await stakingContract.stakedBalance(walletAddress);
            setClaimingRate(Number(rewardRate) * seconds * Number(userStakedBalance) / 1e18 / 10**18);
        }catch (error){
            //console.error(error);
        }
    }




    if( connected ){

        setInterval(() => {
            //checkClaimableReward();
        }, 15000);
        checkStaking();
        getStakingContractDetails();
        getRewardTokenContractDetails();
        checkClaimableReward();
        getClaimingRate();
    }else{
        //console.log("Network Check as next");
        checkNetwork();
    }




    async function addNetworkToMetamask(){
        try {
            await window.ethereum // Or window.ethereum if you don't support EIP-6963.
                .request({
                    method: "wallet_switchEthereumChain",
                    params: [{
                        chainId: "0x2105"
                    }],
                });
            //console.log(provider);
        } catch (switchError) {
            // This error code indicates that the chain has not been added to MetaMask.
            if (switchError.code === 4902) {
                try {
                    await window.ethereum // Or window.ethereum if you don't support EIP-6963.
                        .request({
                            method: "wallet_addEthereumChain",
                            params: [
                                {
                                    "chainId": "0x2105",
                                    "chainName": "Base Mainnet",
                                    "rpcUrls": [
                                        "https://base.meowrpc.com"
                                    ],
                                    "iconUrls": [
                                        "https://icons.llamao.fi/icons/chains/rsz_base.jpg"
                                    ],
                                    "nativeCurrency": {
                                        "name": "ETH",
                                        "symbol": "ETH",
                                        "decimals": 18
                                    },
                                    "blockExplorerUrls": [
                                        "https://basescan.org"
                                    ]
                                },
                            ],
                        });
                } catch (addError) {
                    // Handle "add" error.
                }
            }
            // Handle other "switch" errors.
        }
    }


    // Staking / Unstaking Funktion
    async function doStaking() {

        setIsLoadingStakingButton(true);

        if( !connected ){
            setErrorMessage("Connect your wallet first.");
            return;
        }

        //console.log(stakeAmount);
        if( stakeAmount <= 0 ){
            setErrorMessage("Please enter a valid amount to stake.");
            return;
        }

        if( claimableReward >= 0.001){
            setErrorMessage("Attention! Claim your rewards first. The claimable amount will be reset when you add up staking amount");
        }


        try {
            // Ensure stakeAmount is valid
            if (!stakeAmount || isNaN(stakeAmount) || Number(stakeAmount) <= 0) {
                setErrorMessage("Please enter a valid amount to stake.");
                return;
            }

            //console.log("Try Staking: ");
            const provider = new ethers.BrowserProvider( window.ethereum);
            const signer = await provider.getSigner();
            const stakingContract = new ethers.Contract(stakingContractAddress, stakingContractABI, signer);
            const erc20Token = new ethers.Contract(stakingTokenAddress, stakingTokenABI, signer);

            // Convert stake amount to the correct units
            const stakeValue = ethers.parseUnits(stakeAmount.toString(), 18);

            //console.log("Stake Value: " + stakeValue);

            // Approve the staking contract to spend the tokens
            try{
                // Check allowance first
                const allowance = await erc20Token.allowance(walletAddress, stakingContractAddress);
                //console.log("Remaining Allowance: " + allowance);

                if(allowance < stakeAmount){
                    const approveTx = await erc20Token.approve(stakingContractAddress, stakeValue);
                    await approveTx.wait();
                }

            }catch (error){
                //console.log(JSON.stringify(error));
                if(error.code === "ACTION_REJECTED"){
                    setErrorMessage("Approval Rejected. Please try again.");
                }
                setStaking(false);
                setIsLoadingStakingButton(false);
                return;
            }


            try{
                // Call the stake function on the staking contract
                const stakeTx = await stakingContract.stake(stakeValue);
                await stakeTx.wait();
                setErrorMessage("Staking Successfull!");
                setHashMessage(stakeTx.hash);
                //console.log("Stake Amount: " + stakeAmount);
                //console.log( stakeTx.hash );
                setIsLoadingStakingButton(false);



                // Update API

                const totalStakedByWallet = await stakingContract.stakedBalance( walletAddress );

                //console.log("Update API Here");
                const token = localStorage.getItem('token');
                //console.log("Token:" + token );
                const auth = await axios.get('https://api.thejefftoken.com/protected.php', {
                    headers: {  'Authorization': `Bearer ${token}` }
                });
                //console.log("TotalStakedByWallet: " + totalStakedByWallet);

                if( auth ){
                    const payload = {
                        type: "staking",
                        wallet: walletAddress,
                        stakeAmount: Number(totalStakedByWallet / 1000000000000000000n )
                    }
                    await axios.post('https://api.thejefftoken.com/protected.php', payload, {
                        headers: {'Authorization': `Bearer ${token}`}

                    }).then(response => {

                        setErrorMessage(response.data.message);
                        //window.location.reload();

                    }).catch(error => {
                        // Error on Request
                        //console.error('Error:', error);
                        setIsLoadingStakingButton(false);
                        setErrorMessage(error);
                    });
                }


                updateBalance();
                setIsLoadingStakingButton(false);

            }catch (error){
                //console.log(error.code);
                if(error.code === "ACTION_REJECTED"){
                    setErrorMessage("Transaction Rejected. Please try again.");
                }

                setStaking(false);
                return;
            }
        } catch (error) {
            //console.error(error);
            setErrorMessage("Error staking tokens.");
        }
        //console.log("End doStaking()");
        staking ? setStaking(false) : setStaking(true);
    }


    async function doUnstaking() {
        //console.log("Unstaking Function");

        //console.log(stakeAmount);
        if( stakeAmount <= 0 ){
            setErrorMessage("Please enter a valid amount to unstake.");
            return;
        }

        if( inStakeAmount <= 0 ){
            setErrorMessage("You are not staking any tokens.");
            return;
        }

        try {
            // Convert stake amount to the correct units
            const stakeValue = ethers.parseUnits(stakeAmount.toString(), 18);
            //console.log(stakeValue);
            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();

            const stakingContract = new ethers.Contract(stakingContractAddress, stakingContractABI, signer);
            const stakeTx = await stakingContract.unstake(stakeValue);
            await stakeTx.wait();
            setErrorMessage("Unstaking Successfull!" );
            setHashMessage(stakeTx.hash);

            //console.log( stakeTx.hash );

            updateBalance();


        }catch (error) {
            setErrorMessage("Error unstaking tokens." );
        }



    }


    // Claim Burgers
    async function doClaiming() {
        console.log("Claiming");
        if (claimableReward <= 0) {
            setErrorMessage("No claimable reward.");
            return;
        }
        try{

            console.log("Try Claiming");

            setClaiming(true);
            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            const stakingContract = new ethers.Contract(stakingContractAddress, stakingContractABI, signer);
            const claimTx = await stakingContract.claimReward();
            await claimTx.wait();

            setErrorMessage("Claiming Successfull!" );
            setHashMessage(claimTx.hash);

            setClaiming(false);

            updateBalance();

        }catch(error){
            //console.error(error);
            setClaimingErrorMessage("Transaction Rejected. Please try again." );
            setClaiming(false);
        }
    }


    function changeStakingAmount(e) {
        //console.log("Input Value: " + e.target.value);
        setValue(e.target.value ? Number(e.target.value) : e.target.value)

        if( Math.sign(e.target.value) < 1 ){
            //setErrorMessage("Please enter a valid amount to stake.");
            setValue(1);
            return;
        }

        setStakeAmount(e.target.value);
        setValue(e.target.value);
        //console.log("Stake Amount Changed to: " + e.target.value);
    }

    //console.log("Current Value: " +claimableReward);


    // Render Metamask is not installed
    //console.log(checkMetaMaskInstallation())
    if (!checkMetaMaskInstallation()) {
        return (
            <div>
                <p className={"card"}>MetaMask is not installed. Please install MetaMask to use this application.</p>
                <a href="https://metamask.io/download.html" target="_blank" rel="noopener noreferrer" className={"btn-primary"}>
                    Install MetaMask
                </a>
            </div>
        );
    }


    const handleEndUpdate = () => {
        checkClaimableReward();
    }

    //console.log("Start: " + claimableReward);
    //console.log("End: " + countUpSum );

    //console.log(isMetaMaskInstalled);
    //console.log("Is right network: " + isRightNetwork);

    return (
        <div>
            {!isRightNetwork &&
                <div>
                    <div className="changeNetworkHint">
                        <img alt="Change Network Message" src={IchangeNetwork}/>
                        Switch to the BASE Chain to getting started
                    </div>
                </div>
            }

            {!isMetaMaskInstalled &&
                <div>
                    <div className="changeNetworkHint">
                        Please Install Metamask
                    </div>
                </div>
            }

            <div className="mt-3 mb-10">
                <h2 className="h2">JeffToken (JEFF) Staking</h2>
                <img alt="JeffToken Logo" src={logo} className={"jeff-logo"}/>
                <h3>Let's create some BURGERS</h3>
            </div>

            <div>
                <button
                    className="btn-primary"
                    onClick={connectWallet}
                    disabled={!isRightNetwork}
                >
                    {connected ? "Disconnect Wallet" : "Connect Wallet"}
                </button>
                {!isRightNetwork &&
                    <button
                        className="btn-primary"
                        onClick={addNetworkToMetamask}>
                        Add Base Network
                    </button>
                }
            </div>

            <div>
                {connected && walletAddress !== "" &&
                    <div>
                        <div className="card-full">
                            <h3 className={"h1"}>Address</h3>
                            <span className={"break-words card-content"}>{walletAddress}</span>
                        </div>
                        <div className="grid grid-cols-6 gap-4">
                            <TokenCard
                                tokenBalance={Math.round(stakingTokenBalance)}
                                tokenSymbol={stakingTokenSymbol}
                                tokenName={stakingTokenName}
                            />

                            <TokenCard
                                tokenBalance={rewardTokenBalance}
                                tokenSymbol={rewardTokenSymbol}
                                tokenName={rewardTokenName}
                            />
                        </div>
                    </div>
                }


                {connected && staking &&
                    <div className="card flex justify-center">
                        <h1 className={"h1"}>Staking Progress</h1>
                        <div className="mb-5">JEFF was busy and baked some Burgers <br/>
                            <div className="current-claimable-amount">
                                <span className={
                                    "font-update"}>

                                <CountUp
                                    start={claimableReward - claimingRate < 0 ? 0 : claimableReward - claimingRate}
                                    end={claimableReward}
                                    duration={10}
                                    useEasing={false}
                                    decimals={12}
                                    onEnd={() => handleEndUpdate()}

                                /> {rewardTokenSymbol}
                                    </span>
                            </div>
                            <img alt={"Burger Flipping"} src={burgerflip} className={"staking-icon"}/>
                            <button className="btn-primary" onClick={doClaiming}>
                                {claiming ? "Please Wait..." : "🍔 CLAIM 🍔"}
                            </button>
                            <span
                                className={"error-message"}
                                style={{display: claimingErrorMessage ? "block" : "none"}}
                            >{claimingErrorMessage}</span>
                        </div>
                    </div>
                }

                {connected &&
                    <div className="card flex justify-center">
                        <div className="h1">Staking Amount
                            <span className={"current-staking-amount"}>
                                {inStakeAmount} {stakingTokenSymbol}
                            </span>
                        </div>
                        <div>
                            <input name={"stakeAmount"}
                                   type={"number"}
                                   placeholder="Stake Amount"
                                   min={1}
                                   value={value && Math.max(1, value)}
                                   onChange={changeStakingAmount}
                            />
                            <button
                                disabled={isLoadingStakingButton}
                                className="btn-primary"
                                onClick={doStaking}
                            >
                                {isLoadingStakingButton && (
                                    <svg
                                        aria-hidden="true"
                                        role="status"
                                        className="inline w-4 h-4 me-3 text-white animate-spin"
                                        viewBox="0 0 100 101"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="#E5E7EB"
                                        />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                )}
                                {isLoadingStakingButton ? 'Loading...' : 'Stake JEFF'}
                            </button>
                            <button
                                className="btn-secondary"
                                onClick={doUnstaking}>
                                Unstake JEFF
                            </button>
                        </div>
                    </div>


                }


                {
                    referralId &&
                    <div className="card flex justify-center">
                        <div>
                            <h3 className={"h1"}>Your Referral ID:</h3>
                            <a href={"https://staking.thejefftoken.com/ref/" + referralId} target={"_blank"}
                               rel="noreferrer">
                                {"https://staking.thejefftoken.com/ref/" + referralId}
                            </a>
                        </div>
                    </div>
                }


            </div>
            <div className={"error-message"} style={{display: errorMessage ? "block" : "none"}}>
                {errorMessage}
            </div>
            <div className={"hash-message"} style={{display: hashMessage ? "block" : "none"}}>
                <a href={"https://sepolia.basescan.org/tx/" + hashMessage} target={"_blank"}
                   rel="noreferrer">{hashMessage}</a>
            </div>

            {connected && !referralId &&
                <Referral onData={handleReferralData}></Referral>
            }

        <div>
            <TopStakingBoard value={connected}/>
        </div>
        </div>
    );
}

export default Wallet;