import React, {useState, useEffect} from "react";
import {ethers} from "ethers";
import axios from "axios";
import classNames from 'classnames';

const TopStakingBoard = (props) => {

    //const [stakingListEmpty, setStakingListEmpty] = useState('Currently no stakers');
    const [data, setData] = useState([]);
    const [walletAddress, setWalletAddress] = useState('');


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.thejefftoken.com/index.php');

                if( response.data.status === 1 ){
                    setData(response.data.record);
                    //console.log(response.data.record);
                }

            } catch (error) {
                //console.error('Error fetching data:', error);
            }
        };

        fetchData();

        // Intervall zum wiederholten Abrufen der Daten alle 10 Sekunden (10000 ms)
        const intervalId = setInterval(fetchData, 10000);

        // Bereinigung der Intervall-Funktion beim Unmounten der Komponente
        return () => clearInterval(intervalId);

    }, []);



    useEffect( () => {
        if(props.value){
            const getWalletAddress = async () => {
                const provider = new ethers.BrowserProvider(window.ethereum);
                const signer = await provider.getSigner();
                const _walletAddress = await signer.getAddress();
                setWalletAddress(_walletAddress);
            }
            getWalletAddress();
        }
    });


    return(
        <div>
            <h3 className={"h1 top-staking-board"}>Top Staking Board</h3>
            <div className="">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead>
                        <tr>
                            <th className="px-6 py-4">#</th>
                            <th className="px-6 py-4">Wallet</th>
                            <th className="px-6 py-4">Staking ($JEFF)</th>
                        </tr>
                    </thead>
                    <tbody>
                    {data.length > 0 ? (
                        data.map((item, index) => (

                            <tr key={index}
                                className={classNames("border-b dark:bg-gray-800 dark:border-gray-700", {
                                    'dark:border-yellow-600 dark:bg-yellow-900': props.value && String(item.wallet).toUpperCase() === String(walletAddress).toUpperCase()
                            })}
                            >
                                <td
                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {
                                        index + 1 === 1 ? '🥇' :
                                        index + 1 === 2 ? '🥈' :
                                        index + 1 === 3 ? '🥉' :
                                        index + 1
                                    }
                                    <span className='text-gray-500'> {String(item.wallet).toUpperCase() === String(walletAddress).toUpperCase() ? "(you)" : ""}</span>
                                </td>
                                <td className="px-6 py-4">
                                    <a href={"https://basescan.org/address/" + item.wallet}
                                       target="_blank"
                                       rel="noreferrer">
                                       {item.wallet}
                                    </a>
                                </td>
                                <td className="px-6 py-4">
                                    {item.stakingAmount}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="3" className="px-6 py-4">
                                Currently no stakers
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
export default TopStakingBoard;