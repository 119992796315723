import './App.css';
import Wallet from './Wallet';


function App() {

    const APP_APPVERSION = '0.35.2';
    const APP_BETA = true;





    return (
        <div className="App">
            <Wallet/>
            <div className={"footer-style"}>
              v{APP_APPVERSION} { APP_BETA ? "(beta) enabled by Jeff" : "" }
            </div>
        </div>

    );
}

export default App;
